var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: "-1", color: _vm.corSnackbar, top: false },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  { attrs: { text: "" }, on: { click: _vm.fecharSnackbar } },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", { staticClass: "ma-3" }, [_vm._v("mdi-close")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.mostrarSnackbar,
        callback: function($$v) {
          _vm.mostrarSnackbar = $$v
        },
        expression: "mostrarSnackbar"
      }
    },
    [_vm._v(_vm._s(_vm.mensagemSnackbar) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }