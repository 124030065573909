var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _vm.$route.path !== "/login" ? _c("app-navigation") : _vm._e(),
      _c(
        "v-main",
        { attrs: { id: "principal", height: "100%", width: "100px" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }