var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showModal
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.showModal,
                callback: function($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "lembrete-expiracao-senha-component" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", align: "left" } },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(" mdi-alert-decagram ")
                              ]),
                              _vm._v(" Lembrete de expiração de senha ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Olá " + _vm._s(_vm.userProfile.name) + ", como vai?"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Este é um lembrete que resta" +
                        _vm._s(
                          _vm.userProfile.password_expire_in > 1 ? "m" : ""
                        ) +
                        " "
                    ),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.userProfile.password_expire_in) +
                          " dia" +
                          _vm._s(
                            _vm.userProfile.password_expire_in > 1 ? "s" : ""
                          )
                      )
                    ]),
                    _vm._v(" para que sua senha de acesso expire. "),
                    _c("br"),
                    _vm._v(" Que tal definir sua nova senha?"),
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.doAction("changePasswordNow")
                              }
                            }
                          },
                          [_vm._v("Definir Nova Senha")]
                        ),
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function($event) {
                                return _vm.doAction("changePasswordLater")
                              }
                            }
                          },
                          [_vm._v("Definir a senha depois")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }